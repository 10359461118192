import { PageProps } from 'core/page/page.props';
import { PagePresets } from 'core/page/page.presets';
import React, { useEffect } from 'react';
import { BoxContainer } from 'layout/box-container';
import { NextSeo } from 'next-seo';
import { useLayout } from 'context/layout.context';
import { environment } from 'config/environment';
import { useRouter } from 'next/router';

export const Page = (props: PageProps) => {
  const {
    headerLocked,
    footerVisible,
    backgroundColor,
    hero,
    children,
    minHeight,
  } = Object.assign({}, PagePresets, props);

  const { asPath } = useRouter();

  const seoConfig = Object.assign(
    {
      canonical: `${environment.HOST_URL}${asPath.split('?')[0]}`,
    },
    props.seoConfig,
  );

  const {
    setFooterVisibility,
    setHeaderVisibility,
    lockHeaderVisible,
    unlockHeaderVisible,
  } = useLayout();

  useEffect(() => {
    setTimeout(() => {
      setHeaderVisibility(false);
    }, 0);
  }, []);

  useEffect(() => {
    if (headerLocked) {
      lockHeaderVisible();
    } else {
      unlockHeaderVisible();
    }
  }, [headerLocked]);

  useEffect(() => {
    setFooterVisibility(footerVisible);
  }, [footerVisible]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {seoConfig && <NextSeo {...seoConfig} />}

      <BoxContainer
        backgroundColor={backgroundColor}
        width="100%"
        minHeight={minHeight}>
        {hero}
        {children}
      </BoxContainer>
    </>
  );
};
