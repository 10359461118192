import { HomePage } from 'modules/shop/home';
import { GetStaticProps } from 'next';
import { ghostAPI } from 'services/ghost-api';

export const getStaticProps: GetStaticProps = async ({ params }) => {
  try {
    const posts = await ghostAPI.posts.browse({
      include: 'tags',
      limit: 3,
    });

    return {
      props: {
        posts: posts.map(item =>
          Object.assign({}, item, {
            description: item.excerpt.split('\n')[0],
          }),
        ),
      },
      revalidate: 1,
    };
  } catch (e) {
    throw new Error('[index]: Error connecting to porter api');
  }
};

export default HomePage;
