import styled, { css } from 'styled-components';
import { animated } from 'react-spring';
import { ResolutionAwareProp } from 'types/resolution-aware-prop.type';
import { ExtractStyleFromResolutionAwarePropEntry } from 'types/layout';
import { generateStylesForResolutionAwareProps } from 'helpers/layout.helpers';
import { media } from 'styles/media';
import { getThemeColor } from 'styles/theme';
import { TopProperty } from 'csstype';

export const StyledAnimatedImageContainer = styled(animated.div)<{
  first: number;
  widthpercentage: number;
}>`
  ${({ first, widthpercentage }) => css`
    width: ${widthpercentage * 100}%;
    height: 100%;
    min-width: ${widthpercentage * 100}%;
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 0;
    user-select: none;
    z-index: ${first ? 1 : 0};
    touch-action: pan-y;
  `}
`;

export const StyledCarouselPage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const StyledAnimatedImage = styled(animated.div)<{
  size?: any;
}>`
  ${({ size }) => css`
    width: 100%;
    height: 100%;
    min-width: 100%;
    background-size: ${size};
    background-position: center;
    cursor: pointer;
    user-select: none;
  `}
`;

export const StyledCarouselIconContainer = styled.span<{
  top?: TopProperty<any>;
  bottom?: TopProperty<any>;
  left?: string;
  right?: string;
  notVisible?: ResolutionAwareProp<boolean>;
}>`
  ${({ left, right, notVisible, top, bottom }) => {
    const entries: ExtractStyleFromResolutionAwarePropEntry[] = [
      {
        cssProperty: 'display',
        resolutionAwareProp: notVisible,
        valueFormatter: value => (value === true ? 'none' : 'block'),
      },
    ];

    return css`
      position: absolute;
      left: ${left ?? 'unset'};
      right: ${right ?? 'unset'};
      top: ${top ? top : bottom ? 'unset' : 0};
      bottom: ${bottom ? bottom : top ? 'unset' : 0};
      margin: auto;
      width: 32px;
      height: 32px;
      z-index: 2;
      border-radius: 50%;
      ${generateStylesForResolutionAwareProps(entries)}
    `;
  }}
`;

export const StyledIndicatorsContainer = styled.div<{
  indicatorsHidden;
  indicatorsPosition;
}>`
  ${({ theme, indicatorsHidden, indicatorsPosition }) => {
    const entries: ExtractStyleFromResolutionAwarePropEntry[] = [
      {
        cssProperty: 'display',
        resolutionAwareProp: indicatorsHidden,
        valueFormatter: value => (value === true ? 'none' : 'flex'),
      },
      {
        cssProperty: 'justify-content',
        resolutionAwareProp: indicatorsPosition,
      },
    ];

    return css`
      display: flex;
      position: absolute;
      left: 0;
      right: 0;
      bottom: ${theme.spacing.s3};
      padding: 0 ${theme.spacing.s3};
      z-index: 1;
      ${generateStylesForResolutionAwareProps(entries)};

      ${media.lessThan('sm')`
        padding: 0 ${theme.spacing.s1};
        bottom: ${theme.spacing.s1};
      `}
    `;
  }}
`;

export const StyledIndicator = styled.span<{ color; active }>`
  ${({ color, active }) => {
    const entries: ExtractStyleFromResolutionAwarePropEntry[] = [
      {
        cssProperty: 'background-color',
        resolutionAwareProp: color,
        valueFormatter: value => getThemeColor(value),
      },
    ];
    return css`
      width: 20px;
      height: 1px;
      margin: 0 5px;
      opacity: ${active ? 1 : 0.5};
      display: inline-block;
      ${generateStylesForResolutionAwareProps(entries)};
    `;
  }}
`;

export const StyledScrollIndicator = styled(animated.div)`
  ${({ theme }) => css`
    height: 3px;
    background-color: ${theme.colors.black};
    position: absolute;
    bottom: -2px;
    z-index: 1;
    border-radius: 2rem;
    width: 4rem;
  `}
`;

export const StyledCarouselFooter = styled(animated.div)`
  position: relative;
`;
