import React, { useEffect, useRef, useState } from 'react';
import { CarouselProps } from './carousel.props';
import {
  StyledAnimatedImage,
  StyledAnimatedImageContainer,
  StyledCarouselFooter,
  StyledCarouselIconContainer,
  StyledCarouselPage,
  StyledIndicator,
  StyledIndicatorsContainer,
  StyledScrollIndicator,
} from 'core/carousel/carousel.styles';
import { useDrag } from 'react-use-gesture';
import { SpringValue, useSpring, useSprings } from 'react-spring';
import { SizedContainer } from 'layout/sized-container';
import { CarouselPresets } from 'core/carousel/carousel.presets';
import { getResolutionPrefix } from 'helpers/layout.helpers';
import useResizeObserver from 'use-resize-observer/polyfilled';
import { BoxContainer } from 'layout/box-container';
import { getThemeColor } from 'styles/theme';
import range from 'lodash/range';
import { ImageContainer } from 'layout/image-container';
import { Icon } from 'core/icon/icon';

export const Carousel = (props: CarouselProps) => {
  const {
    currentIndex,
    aspectRatio,
    width,
    height,
    numberOfPages,
    images,
    imagesSource,
    imagesObjectFit,
    imagesBackgroundColor,
    hidden,
    controlsHidden,
    controlsPosition,
    gesturesDisabled,
    indicatorsHidden,
    indicatorsPosition,
    indicatorsColor,
    containerBackgroundColor,
    pageBackgroundColor,
    backgroundSize,
    scrollIndicatorVisible,
    renderContent,
    pageWidthPercentage,
    alt,
    footer,
    onPageChanged,
  } = Object.assign({}, CarouselPresets, props);
  const [originalPages, setOriginalPages] = useState([]);
  const [innerPages, setInnerPages] = useState([]);
  const prefix = getResolutionPrefix();
  const [activeIndex, setActiveIndex] = useState(currentIndex);
  const index = useRef(0);
  const { width: containerWidth, ref: measureRef } = useResizeObserver();

  const [animationProps, setAnimationProps] = useSprings(
    innerPages.length,
    index => ({
      x: index * (containerWidth ? containerWidth * pageWidthPercentage : 0),
    }),
  );

  const [scrollSpring, setScrollSpring] = useSpring(() => ({
    x: 0,
    width: 0,
  }));

  useEffect(() => {
    if (containerWidth && innerPages?.length) {
      setScrollSpring({ width: containerWidth / innerPages.length });
    }
  }, [containerWidth, innerPages?.length]);

  useEffect(() => {
    const nextX = (containerWidth / innerPages.length) * activeIndex;
    setScrollSpring({ x: nextX || 0 });
  }, [activeIndex]);

  useEffect(() => {
    let totalPages = numberOfPages ?? images?.length;

    setOriginalPages(range(0, totalPages));

    if (pageWidthPercentage < 1) {
      totalPages += 1;
    }

    setInnerPages(range(0, totalPages));
  }, [numberOfPages, images?.length, pageWidthPercentage]);

  const goToPage = (newIndex: number): void => {
    setActiveIndex(index.current);

    if (onPageChanged) {
      onPageChanged(newIndex);
    }
  };

  const updateAnimationProps = async (
    animate: boolean = false,
  ): Promise<void> => {
    await setAnimationProps(i => ({
      x:
        (i - index.current) *
        (containerWidth ? containerWidth * pageWidthPercentage : 0),
      immediate: !animate,
      config: {
        duration: animate ? 250 : 0,
      },
    }));
  };

  useEffect(() => {
    index.current = currentIndex;
    goToPage(currentIndex);
    updateAnimationProps(true);
  }, [currentIndex]);

  useEffect(() => {
    if (containerWidth) {
      updateAnimationProps();
    }
  }, [containerWidth]);

  useEffect(() => {
    index.current = 0;
    goToPage(0);
    updateAnimationProps();
  }, [prefix]);

  const previous = async () => {
    if (index.current > 0) {
      index.current = index.current - 1;
      goToPage(index.current);
      await updateAnimationProps(true);
    }
  };

  const next = async () => {
    const lastIndex =
      index.current === innerPages.length - (pageWidthPercentage < 1 ? 2 : 1);

    if (!lastIndex) {
      index.current = index.current + 1;
      goToPage(index.current);
      await updateAnimationProps(true);
    }
  };

  const bind = useDrag(
    async ({ down, movement: [mx], direction: [xDir], distance, event }) => {
      if (!gesturesDisabled && !down && distance > 50) {
        if (xDir > 0) {
          await previous();
        } else {
          await next();
        }
      }

      setAnimationProps(i => {
        const x =
          (i - index.current) * ((containerWidth ?? 0) * pageWidthPercentage) +
          (down ? mx : 0);

        return {
          x,
        };
      });
    },
    {
      axis: 'x',
      lockDirection: true,
      domTarget: measureRef,
    },
  );

  const canShowControls = images?.length > 1 || numberOfPages > 1;

  const renderPage = (index: number, x: SpringValue) => {
    const lastIndex = index === innerPages.length - 1;
    const imageSrc =
      lastIndex && pageWidthPercentage < 1 ? images[0] : images[index];
    const content =
      renderContent &&
      renderContent(lastIndex && pageWidthPercentage < 1 ? 0 : index);

    return (
      <StyledAnimatedImageContainer
        key={index}
        style={{ x }}
        first={index === 0 ? 1 : 0}
        widthpercentage={pageWidthPercentage}>
        <StyledAnimatedImage
          size={backgroundSize}
          {...(gesturesDisabled ? {} : bind())}>
          {!numberOfPages && imageSrc && (
            <ImageContainer
              src={imageSrc}
              aspectRatio={aspectRatio}
              source={imagesSource}
              objectFit={imagesObjectFit}
              backgroundColor={imagesBackgroundColor}
              containerHeight="100%"
              alt={!index ? alt : ''}>
              {content}
            </ImageContainer>
          )}

          {!!numberOfPages && (
            <StyledCarouselPage
              style={{
                backgroundColor: getThemeColor(pageBackgroundColor),
              }}>
              {content}
            </StyledCarouselPage>
          )}
        </StyledAnimatedImage>
      </StyledAnimatedImageContainer>
    );
  };

  return (
    <BoxContainer
      ref={measureRef}
      hidden={hidden}
      overflow="hidden"
      height={height}
      width={width}
      tabIndex={-1}>
      <SizedContainer
        backgroundColor={containerBackgroundColor}
        aspectRatio={aspectRatio}
        width={width}
        height={height}>
        {animationProps.map(({ x }, index: number) => renderPage(index, x))}
        <StyledCarouselIconContainer
          left="30px"
          {...(controlsPosition || {})}
          onClick={previous}
          notVisible={canShowControls ? controlsHidden : true}>
          <Icon
            name="previous"
            color="translucentBlack"
            size="3.2rem"
            cursor="pointer"
          />
        </StyledCarouselIconContainer>

        <StyledCarouselIconContainer
          right="30px"
          {...(controlsPosition || {})}
          onClick={next}
          notVisible={canShowControls ? controlsHidden : true}>
          <Icon
            name="next"
            color="translucentBlack"
            size="3.2rem"
            cursor="pointer"
          />
        </StyledCarouselIconContainer>

        <StyledIndicatorsContainer
          indicatorsHidden={canShowControls ? indicatorsHidden : true}
          indicatorsPosition={indicatorsPosition}>
          {originalPages.map((page, idx) => (
            <StyledIndicator
              key={idx}
              color={indicatorsColor}
              active={activeIndex === idx}
            />
          ))}
        </StyledIndicatorsContainer>

        {scrollIndicatorVisible && (
          <StyledScrollIndicator style={scrollSpring} />
        )}
      </SizedContainer>

      <StyledCarouselFooter>{footer}</StyledCarouselFooter>
    </BoxContainer>
  );
};
