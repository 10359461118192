import { CarouselProps } from './carousel.props';

export const CarouselPresets: CarouselProps = {
  images: [],
  imagesSource: 'local',
  controlsHidden: { sm: true },
  indicatorsHidden: { md: true },
  scrollIndicatorVisible: false,
  indicatorsPosition: 'flex-end',
  indicatorsColor: 'white',
  containerBackgroundColor: 'lightGrey',
  controlsPosition: { top: 0, bottom: 0 },
  pageBackgroundColor: 'white',
  pageWidthPercentage: 1,
  backgroundSize: 'cover',
  imagesObjectFit: 'cover',
  displayScaleAnimation: true,
  alt: '',
};
