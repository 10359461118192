import React from 'react';
import { ImageContainer } from 'layout/image-container';
import { StackedContainer } from 'layout/stacked-container';
import { Typography } from 'core/typography';
import { Button } from 'core/button';
import { BoxContainer } from 'layout/box-container';
import { SizedContainer } from 'layout/sized-container';
import { ResolutionAwareProp } from 'types/resolution-aware-prop.type';
import { Icon } from 'core/icon';
import { Badge } from 'core/badge';

export const AmmunitionSection = () => {
  const renderContent = (hidden: ResolutionAwareProp<boolean> = false) => {
    return (
      <StackedContainer
        alignContent="center"
        justifyItems={{ lg: 'flex-start', md: 'center' }}
        horizontalPadding="8.33%"
        hidden={hidden}
        gap="s2">
        <Badge variant="h5" tx="home:ammunition.badge" color="white" />

        <ImageContainer
          src="pages/home/logo-ammunition.png"
          width="19rem"
          height="2.6rem"
          backgroundColor="unset"
        />

        <BoxContainer marginTop="s1" maxWidth="44rem">
          <Typography
            tx="home:ammunition.name"
            color="white"
            variant="p1"
            textStyle="bold"
            align={{ lg: 'left', md: 'center' }}
          />
          <Typography
            tx="home:ammunition.description"
            color="translucentWhite"
            variant="p1"
            align={{ lg: 'left', md: 'center' }}
          />
        </BoxContainer>

        <BoxContainer marginTop={{ lg: 's2', md: 's1' }}>
          <Button
            tx="home:ammunition.discover"
            textStyle="regular"
            variant="action"
            color="white"
            icon={
              <Icon
                name="arrow-right"
                color="white"
                width="1.9rem"
                height="1.5rem"
              />
            }
            textVariant="p1"
            iconLocation="right"
            linkHref="/ammunition"
          />
        </BoxContainer>
      </StackedContainer>
    );
  };

  return (
    <BoxContainer
      horizontalMargin={{ lg: 's3', sm: 's1' }}
      marginTop={{ lg: 's3', sm: 's1' }}>
      <ImageContainer
        src={{
          lg: 'pages/home/ammunition.jpg',
          sm: 'pages/home/ammunition-square.jpg',
        }}
        backgroundColor="lightGrey"
        aspectRatio={{ lg: '1:0.50', md: '1:1' }}
        alt="Shop lights by Studio Elk">
        {renderContent({ lg: false, md: true })}
      </ImageContainer>
      <SizedContainer
        backgroundColor="forest"
        aspectRatio="1:1"
        hidden={{ lg: true, md: false }}>
        {renderContent()}
      </SizedContainer>
    </BoxContainer>
  );
};
