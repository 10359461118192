import React, { useState } from 'react';
import { Page } from 'core/page';
import {
  AwardsSection,
  DesignerAwardsSection,
  GetInspiredSection,
  GiftCardsSection,
  HeroSection,
  PressSection,
  ReachSection,
  ShippingSection,
  SprkStudioSection,
  SubscribeSection,
} from 'modules/shop/home/sections';
import { BoxContainer } from 'layout/box-container';
import { StudioElkSection } from 'src/modules/shop/home/sections/studio-elk';
import { AmmunitionSection } from 'modules/shop/home/sections/ammunition';
import { WordSection } from 'modules/shop/home/sections/word';
import { AUTH_TOKEN } from 'constants/common';
import { useCookies } from 'react-cookie';
import { useEffectOnce } from 'react-use';
import { useSubscribeModal } from 'modals/subscribe';
import { useSubscribe } from 'src/common/hooks';
import { useAuth } from 'context/auth.context';
import { PostsSection } from 'modules/shop/home/sections/posts';

export const HomePage = ({ posts }) => {
  const [{ authenticated }] = useAuth();
  const [cookies] = useCookies([AUTH_TOKEN]);
  const [showSubscribeSection, setShowSubscribeSection] = useState(false);

  useSubscribeModal();

  const subscribe = useSubscribe({ analyticsEvent: 'Sign Up - Home Page' });

  useEffectOnce(() => {
    setShowSubscribeSection(!cookies[AUTH_TOKEN]);
  });

  return (
    <Page hero={<HeroSection />} headerLocked={true}>
      <AwardsSection />
      <DesignerAwardsSection />
      <SprkStudioSection />
      <ReachSection />
      <StudioElkSection />
      <WordSection />
      <AmmunitionSection />
      <PressSection />
      <GetInspiredSection />
      <GiftCardsSection />
      <ShippingSection />
      <PostsSection posts={posts} />

      {!authenticated && showSubscribeSection && (
        <BoxContainer marginBottom={{ lg: 's6', md: 's5' }}>
          <SubscribeSection subscribe={subscribe} />
        </BoxContainer>
      )}
    </Page>
  );
};

export default HomePage;
