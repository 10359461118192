import React, { forwardRef } from 'react';
import { BadgeProps } from 'core/badge/badge.props';
import { BadgePresets } from 'core/badge/badge.presets';
import { StyledBadge, StyledBadgeTypography } from 'core/badge/badge.styles';
import { Typography } from 'core/typography';

export const Badge = forwardRef((props: BadgeProps, ref: any) => {
  const {
    color,
    backgroundColor,
    radius,
    top,
    hidden,
    icon,
    gap,
    ...others
  } = Object.assign({}, BadgePresets, props);

  return (
    <StyledBadge
      ref={ref}
      color={color}
      background={backgroundColor}
      radius={radius}
      top={top}
      hidden={hidden}
      gap={gap}
      hasIcon={!!icon}>
      {icon}
      <StyledBadgeTypography color={color} {...others} />
    </StyledBadge>
  );
});
