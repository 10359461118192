import React, { useCallback } from 'react';
import { PostCard } from 'modules/blog/home/sections/post-card';
import { StackedContainer } from 'layout/stacked-container';
import { FlexContainer } from 'layout/flex-container';
import { Typography } from 'core/typography';
import { Button } from 'core/button';
import { Icon } from 'core/icon';
import { PostsProps } from 'modules/shop/home/sections/posts/posts.props';
import { ScrollableList } from 'core/scrollable-list';

export const PostsSection = ({ posts }: PostsProps) => {
  const renderItem = useCallback(
    (position: number) => {
      return (
        <PostCard post={posts[position]} showDate={true} showTags={false} />
      );
    },
    [posts?.length],
  );

  return (
    <StackedContainer
      padding="unset"
      backgroundColor="cream"
      verticalPadding={{ lg: 's6', md: 's5' }}
      horizontalPadding={{ lg: '8.33%', md: 's3', sm: 's1' }}
      gap={{ lg: 's5', md: 's4' }}>
      <FlexContainer justifyContent={'space-between'} alignItems="center">
        <Typography variant="h2" text="Gantri stories" />

        <Button
          text="Our new Blog"
          variant="action"
          linkHref="/blog"
          icon={
            <Icon
              name="arrow-right"
              color="primary"
              width="1.9rem"
              height="1.5rem"
            />
          }
          iconLocation="right"
        />
      </FlexContainer>

      <ScrollableList
        itemsCount={posts.length}
        renderItem={renderItem}
        itemsPerPage={{ lg: 3, md: 1.5 }}
        gapVisible={true}
        padding="unset"
      />
    </StackedContainer>
  );
};
