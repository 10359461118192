import styled, { css } from 'styled-components';
import { getThemeColor } from 'styles/theme';
import { BadgeProps } from 'core/badge/badge.props';
import { ExtractStyleFromResolutionAwarePropEntry } from 'types/layout';
import { generateStylesForResolutionAwareProps } from 'helpers/layout.helpers';
import { Typography } from 'core/typography';

export const StyledBadge = styled.span<
  BadgeProps & { background: string; hasIcon: boolean }
>`
  ${({ color, background, radius, top, hidden, gap, hasIcon }) => {
    const entries: ExtractStyleFromResolutionAwarePropEntry[] = [
      {
        cssProperty: 'top',
        resolutionAwareProp: top,
      },
      {
        cssProperty: 'display',
        resolutionAwareProp: hidden,
        valueFormatter: value => (value ? 'none' : 'inline-grid'),
      },
      {
        cssProperty: 'column-gap',
        resolutionAwareProp: gap,
      },
    ];

    return css`
      border: 1px solid ${getThemeColor(background || color)};
      border-radius: ${radius};
      display: inline-grid;
      grid-template-columns: ${hasIcon ? `repeat(2, max-content)` : '1fr'};
      pointer-events: none;
      height: 2.4rem;
      padding: 0 1rem;
      align-items: center;
      justify-content: center;
      max-width: max-content;
      position: relative;
      background-color: ${getThemeColor(background)};
      ${generateStylesForResolutionAwareProps(entries)};
    `;
  }}
`;

export const StyledBadgeTypography = styled(Typography)`
  height: 2.2rem;
`;
